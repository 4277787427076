import { showConfirmDialog } from 'vant';
import { nfgExReceive } from "@/api/index";
import { mdToast } from '../../../../utils/tools';
export default {
  data() {
    return {
      from_hash: '',
      describe: '1',
      //1: 马换羊 2: 羊换马
      map: {
        '1': '“金色马”换成“金色羊”',
        '2': '“金色羊”换成“金色马”'
      }
    };
  },
  beforeMount() {
    document.title = "藏品换回";
    this.describe = this.$route.query.describe ? this.$route.query.describe : '1';
    this.from_hash = this.$route.query.from_hash ? this.$route.query.from_hash : '';
  },
  methods: {
    change() {
      if (!this.from_hash) return;
      showConfirmDialog({
        confirmButtonColor: '#00c4a1',
        message: `确认将${this.map[this.describe]}？`
      }).then(async () => {
        let option = {
          from_hash: this.from_hash
        };
        const res = await nfgExReceive(option);
        if (res.status == 0) {
          mdToast('藏品已换回');
          /* eslint-disable */
          graft.app({
            functionName: "closeController",
            webFun: e => {
              window.location.go(-1);
            }
          });
        } else {
          mdToast(res.message);
        }
      }).catch(err => console.log(err));
    }
  }
};